import * as React from 'react';
import i18next from 'i18next';
import * as expertiseStyles from './expertise.module.scss';


const Expertise = () => {

    const expertise = React.useMemo(()=>{
        return i18next
            .t('expertise.expertise', { returnObjects: true })
            ?.reduce((p, c)=> {
                if (p[p.length - 1].length < 2){
                    p[p.length-1].push(c);
                } else {
                    p.push([c]);
                }
                return p;
            }, [[]]) ?? [];
    }, [])

    return (
        <section className={expertiseStyles.expertise}>
            <h2 className={`mb-5r mt-5r`}>{i18next.t('expertise.title')}</h2>

            {
                expertise.map((values, i) => {
                        return (
                            <div key={`expertise-row-${i}`} className={expertiseStyles.expertise__row}>
                                {
                                    values.map((expertise, j) => {
                                        return (
                                            <div key={`expertise-column-${i}-${j}`} className={expertiseStyles.expertise__col}>
                                                <div className={expertiseStyles.expertise__flex}>
                                                    <small className={expertiseStyles.expertise__point}>{expertise.key}</small>
                                                    <div className={expertiseStyles.expertise__reason}>
                                                        <div className={expertiseStyles.expertise__reasonTitle}>
                                                            {expertise.title}
                                                        </div>
                                                        <div className={expertiseStyles.expertise__reasonDesc}>{expertise.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
            }
        </section>

    )
}
export default Expertise
